import React from 'react';

// components
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

// sections
import AboutConference from '../components/sections/AboutConference';
import Hero from '../components/sections/Hero';
import MailingList from '../components/sections/MailingList';
import Shop from '../components/sections/Shop';
import Social from '../components/sections/Social';
import SpeakerSummaries from '../components/sections/SpeakerSummaries';
import Sponsors from '../components/sections/Sponsors';
import Testimonials from '../components/sections/Testimonials';

// styled
import Container from '../components/styled/Container';

class Index extends React.Component {
  render() {
    return (
      <Container>
        <SEO />
        <Nav selected="/" />
        <Hero />
        <AboutConference />
        <SpeakerSummaries />
        <Social />
        <Shop />
        <Sponsors />
        <Testimonials />
        <MailingList />
        <Footer />
      </Container>
    );
  }
}

export default Index;
