import React from 'react';
import { Link } from 'gatsby'

// styled
import Section from '../styled/Section';
import Superscript from '../styled/Superscript';
import Wrapper from '../styled/Wrapper';
import Grid from '../styled/Grid';

// assets
import cbc from '../../assets/sponsors/cbc.png';
import chroma from '../../assets/sponsors/chroma.png';
import cisco from '../../assets/sponsors/cisco.png';
import dapper from '../../assets/sponsors/dapper.png';
import jetbrains from '../../assets/sponsors/jetbrains.png';
import joyent from '../../assets/sponsors/joyent.png';
import microsoft from '../../assets/sponsors/microsoft.png';
import percy from '../../assets/sponsors/percy.svg';
import rangle from '../../assets/sponsors/rangle.png';
import saucelabs from '../../assets/sponsors/sauce-labs.png';
import cypress from '../../assets/sponsors/cypress.png';
import testingjavascript from '../../assets/sponsors/testing-javascript.png';

const SelectedClients = () => (
  <Section noPaddingTop center>
    <Wrapper>
      <Superscript>Sponsors</Superscript>

      <Superscript small noMargin>
        Platinum
      </Superscript>
      <Grid large>
        <div>
          <a href="https://percy.io/">
            <img src={percy} />
          </a>
        </div>
      </Grid>

      <Superscript small noMargin>
        Gold
      </Superscript>
      <p><Link to='/sponsorship'>Gold sponsorships available!</Link></p>

      <Grid medium>
        <div>
          <a href="https://rangle.io/">
            <img src={rangle} />
          </a>
        </div>

        <div>
          <a href="https://www.cbc.ca/">
            <img src={cbc} />
          </a>
        </div>
      </Grid>

      <Superscript small noMargin>
        Silver
      </Superscript>
      <p><Link to='/sponsorship'>Silver sponsorships available!</Link></p>
      <Grid medium>
        <div>
          <a href="https://blogs.msdn.microsoft.com/cdndevs/">
            <img src={microsoft} />
          </a>
        </div>
      </Grid>

      <Superscript small noMargin>
        Lunch Sponsor
      </Superscript>
      <Grid medium>
        <div>
          <a href="https://saucelabs.com/">
            <img src={saucelabs} />
          </a>
        </div>
      </Grid>

      <Superscript small noMargin>
        After-party Sponsor
      </Superscript>
      <Grid medium>
        <div>
          <a href="https://www.cypress.io//">
            <img src={cypress} />
          </a>
        </div>
      </Grid>

      <Superscript small noMargin>
        Travel sponsors
      </Superscript>
      <Grid medium>
        <div>
          <a href="https://hichroma.com/">
            <img src={chroma} />
          </a>
        </div>
        <div>
          <a href="https://www.joyent.com/">
            <img src={joyent} />
          </a>
        </div>
        <div>
          <a href="https://www.dapperlabs.com/">
            <img src={dapper} />
          </a>
        </div>
      </Grid>

      <Superscript small noMargin>
        Community partners
      </Superscript>
      <Grid medium>
        <div>
          <a href="https://testingjavascript.com/">
            <img src={testingjavascript} />
          </a>
        </div>
        <div>
          <a href="https://www.jetbrains.com">
            <img src={jetbrains} />
          </a>
        </div>
        <div>
          <a href="https://www.cisco.com/c/m/en_ca/innovationcenter/toronto.html">
            <img src={cisco} />
          </a>
        </div>
      </Grid>
    </Wrapper>
  </Section>
);

export default SelectedClients;
