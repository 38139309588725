import React from 'react';
import { Link } from 'gatsby';

// styled
import Column from '../styled/Column';
import DownArrow from '../styled/DownArrow';
import Row from '../styled/Row';
import Section from '../styled/Section';
import Superscript from '../styled/Superscript';
import Wrapper from '../styled/Wrapper';

const Social = () => (
  <Section noPaddingTop dotDetail="left">
    <Wrapper>
      <Row>
        <Column>
          <Superscript>More than talks</Superscript>
        </Column>
        <Column>
          <p>
          We think a conference should be much more than just great talks, it's a chance to make connections with people who are working on, or have solved, some of the same challenges as you.  Connect with the speakers and other attendees during the conference day and at the after-party!
          </p>
          <p>
            <Link to="/location">Learn more about Toronto, our venue, and the after-party</Link>.
          </p>
        </Column>
      </Row>
    </Wrapper>
  </Section>
);

export default Social;
