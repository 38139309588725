import React from 'react';
import { Link } from 'gatsby';
import styled, { keyframes, css } from 'styled-components';

// styled
import Superscript from '../styled/Superscript';
import Button from '../styled/Button';
import Speaker from '../styled/Speaker';
import SpeakerName from '../styled/SpeakerName';
import DownArrow from '../styled/DownArrow';
import Section from '../styled/Section';
import Wrapper from '../styled/Wrapper';
import Row from '../styled/Row';
import Column from '../styled/Column';

import knuckles from '../../assets/knuckles.png';

class Shop extends React.Component {
  render() {
    return (
      <Section noPaddingTop dotDetail="right">
        <Wrapper>
          <Row>
            <Column>
              <Superscript>Stickers and Shirts</Superscript>
            </Column>
            <Column>
              <p>
                They're not just conference swag, we made some awesome "Unit Test" stickers and tees that you'll definitely want to see. We have a few leftovers so get them while they're still around!
                <p style={{textAlign: 'center', margin: '20px'}}>
                <img src={knuckles} style={{width: '50%'}}/>
                </p>
              </p>
              <Button href="https://shop.assertjs.com/">
                Check out the merch
              </Button>
            </Column>
          </Row>
        </Wrapper>
      </Section>
    );
  }
}

export default Shop;
