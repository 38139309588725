import React from 'react';

// styled
import Section from '../styled/Section';
import Superscript from '../styled/Superscript';
import Wrapper from '../styled/Wrapper';
import Row from '../styled/Row';
import Column from '../styled/Column';

const Testimonials = () => (
  <Section noPaddingTop dotDetail="right">
    <Wrapper>
      <Superscript>What they said about last year</Superscript>
      <p>
        <strong />&nbsp;
        The answer to the question "can you have an entire conf dedicated to JS testing" was a resounding "YES!"
        <br />
        <em>— Kevin Lamping, Attendee</em>
      </p>
      <p>
        <strong />&nbsp;
        I thoroughly enjoyed the Assert(js) conference in San Antonio a couple of weeks ago and am already putting some of what I learned there into practice.
        <br />
        <em>— Willie Alberty, Attendee</em>
      </p>
      <p>
        <strong />&nbsp;
        “AssertJS was fantastic! I look forward to next year. I have so much material to digest.”
        <br />
        <em>— Nate Smith, Attendee</em>
      </p>
      <p>
        <strong />&nbsp;
        “Had a great time at #AssertJS !!! Great devs and amazing speakers! 💯👏”
        <br />
        <em>— Jess Liv, Attendee</em>
      </p>
      <p>
        <strong />&nbsp;
        “The core staff that ran the event did a fantastic job. I hope I can attend the event next year. I left this event feeling motivated to improve testing in my organization and generally more energized by having had the opportunity to talk with other developers. If you get the chance, you should go as well.”
        <br />
        <em>— Denny Headrick, Attendee</em>
      </p>
      <p>
        <strong />&nbsp;
        Thank you to the organizers of @assertjs! The conference was awesome and I can’t wait for next year!
        <br />
        <em>— Robert DeLuca, Attendee</em>
      </p>
      <p>
        <strong />&nbsp;
        Really enjoyed #AssertJS. Nice mix of new technologies and wide (sometimes conflicting) ideas about how &amp; what to test.
        <br />
        <em>— Joshua Ohlman, Attendee</em>
      </p>
      <p>
        <strong />&nbsp;
        Congrats to #AssertJS for putting on an amazing event. Excellent content and conversations. Looking forward to next year!
        <br />
        <em>— Randall Kent, Attendee</em>
      </p>

    </Wrapper>
  </Section>
);

export default Testimonials;
