import React from 'react';
import { Link } from 'gatsby';
import styled, { keyframes, css } from 'styled-components';

// styled
import Superscript from '../styled/Superscript';
import Button from '../styled/Button';
import Speaker from '../styled/Speaker';
import SpeakerName from '../styled/SpeakerName';
import DownArrow from '../styled/DownArrow';
import Section from '../styled/Section';
import Wrapper from '../styled/Wrapper';

// assets
import adam from '../../assets/speakers2019/adam.jpg';
import carolina from '../../assets/speakers2019/carolina.jpg';
import chris from '../../assets/speakers2019/chris.jpg';
import clare from '../../assets/speakers2019/clare.jpg';
import colin from '../../assets/speakers2019/colin.png';
import isaac from '../../assets/speakers2019/isaac.jpg';
import james from '../../assets/speakers2019/james.jpg';
import jason from '../../assets/speakers2019/jason.jpg';
import josh from '../../assets/speakers2019/josh.jpg';
import michael from '../../assets/speakers2019/michael.jpg';
import nancy from '../../assets/speakers2019/nancy.jpg';
import ryan from '../../assets/speakers2019/ryan.jpg';

class Speakers extends React.Component {
  render() {
    return (
      <Section noPaddingTop center>
        <Wrapper>
          <DownArrow />
          <Superscript>Speakers</Superscript>
          <SpeakersContainer>
            <Speaker href="https://www.jamesshore.com/">
              <img src={james} />
              <SpeakerName>James Shore</SpeakerName>
            </Speaker>
            <Speaker href="http://www.carolinapinzon.com/">
              <img src={carolina} />
              <SpeakerName>Carolina Pinzon</SpeakerName>
              Dapper Labs
            </Speaker>
            <Speaker href="https://twitter.com/palmerj3">
              <img src={jason} />
              <SpeakerName>Jason Palmer</SpeakerName>
              Spotify
            </Speaker>
            <Speaker href="https://twitter.com/cjihrig">
              <img src={colin} />
              <SpeakerName>Colin Ihrig</SpeakerName>
              Joyent
            </Speaker>
            <Speaker href="https://twitter.com/izs">
              <img src={isaac} />
              <SpeakerName>Isaac Z. Schlueter</SpeakerName>
              npm
            </Speaker>
            <Speaker href="https://twitter.com/agarcher">
              <img src={adam} />
              <SpeakerName>Adam Archer</SpeakerName>
              Shopify
            </Speaker>
            <Speaker href="http://nancydu.me/">
              <img src={nancy} />
              <SpeakerName>Nancy Du</SpeakerName>
              Rangle.io
            </Speaker>
            <Speaker href="https://twitter.com/mshilman">
              <img src={michael} />
              <SpeakerName>Michael Shilman</SpeakerName>
              Chroma, Storybook
            </Speaker>
            <Speaker href="https://twitter.com/CodingItWrong">
              <img src={josh} />
              <SpeakerName>Josh Justice</SpeakerName>
              Big Nerd Ranch
            </Speaker>
            <Speaker href="https://twitter.com/clarmso">
              <img src={clare} />
              <SpeakerName>Clare So</SpeakerName>
              OTTO Motors
            </Speaker>
            <Speaker href="https://twitter.com/ryan_marsh">
              <img src={ryan} />
              <SpeakerName>Ryan Marsh</SpeakerName>
              thestack.io
            </Speaker>
          </SpeakersContainer>
          <Button href="https://www.youtube.com/playlist?list=PLZ66c9_z3umMtAboEKsHXQWB0YMJje7Tl">
            Watch the talks
          </Button>
        </Wrapper>
      </Section>
    );
  }
}

const SpeakersContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

export default Speakers;
