import React from 'react';

// styled
import Section from '../styled/Section';
import Superscript from '../styled/Superscript';
import Wrapper from '../styled/Wrapper';
import Row from '../styled/Row';
import Column from '../styled/Column';

const AboutConference = () => (
  <Section squareDetail="right" dotDetail="left">
    <Wrapper>
      <Row>
        <Column>
          <Superscript>Why are we doing this?</Superscript>
        </Column>
        <Column>
          <p>
            As developers we know that automated testing helps us write better
            code, build more reliable products, and keep our development
            velocity consistently high. As JavaScript developers we also know
            that our platform evolved from a place where testing wasn’t always
            highly in focus.
          </p>
          <p>
            However, JavaScript has become a modern app development platform and
            JS testing tools have undergone multiple generations of progress in
            a few short years. The Assert(js) Conference intends to help JS
            developers of all types better understand and share their best
            practices and experiences with JS testing tools.
          </p>
        </Column>
      </Row>
    </Wrapper>
  </Section>
);

export default AboutConference;
