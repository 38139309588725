import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const DownArrow = () => (
  <SvgContainer
    width="125"
    height="125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#fff">
      <g>
        <rect
          x="16.971"
          y="45.255"
          width="4"
          height="4"
          transform="rotate(-45 16.97 45.255)"
        />
        <rect
          x="25.456"
          y="53.74"
          width="4"
          height="4"
          transform="rotate(-45 25.456 53.74)"
        />
        <rect
          x="33.941"
          y="62.225"
          width="4"
          height="4"
          transform="rotate(-45 33.941 62.225)"
        />
        <rect
          x="42.426"
          y="70.711"
          width="4"
          height="4"
          transform="rotate(-45 42.426 70.71)"
        />
        <rect
          x="50.912"
          y="79.196"
          width="4"
          height="4"
          transform="rotate(-45 50.912 79.196)"
        />
        <rect
          x="59.397"
          y="87.681"
          width="4"
          height="4"
          transform="rotate(-45 59.397 87.681)"
        />
        <rect
          x="67.882"
          y="79.196"
          width="4"
          height="4"
          transform="rotate(-45 67.882 79.196)"
        />
        <rect
          x="104.652"
          y="42.426"
          width="4"
          height="4"
          transform="rotate(45 104.652 42.426)"
        />
        <rect
          x="96.166"
          y="50.912"
          width="4"
          height="4"
          transform="rotate(45 96.166 50.912)"
        />
        <rect
          x="87.681"
          y="59.397"
          width="4"
          height="4"
          transform="rotate(45 87.681 59.397)"
        />
        <rect
          x="79.196"
          y="67.882"
          width="4"
          height="4"
          transform="rotate(45 79.196 67.882)"
        />
      </g>
      <g>
        <rect
          x="16.971"
          y="62.225"
          width="4"
          height="4"
          transform="rotate(-45 16.97 62.225)"
        />
        <rect
          x="25.456"
          y="70.711"
          width="4"
          height="4"
          transform="rotate(-45 25.456 70.71)"
        />
        <rect
          x="33.941"
          y="79.196"
          width="4"
          height="4"
          transform="rotate(-45 33.941 79.196)"
        />
        <rect
          x="42.426"
          y="87.681"
          width="4"
          height="4"
          transform="rotate(-45 42.426 87.681)"
        />
        <rect
          x="50.912"
          y="96.166"
          width="4"
          height="4"
          transform="rotate(-45 50.912 96.166)"
        />
        <rect
          x="59.397"
          y="104.652"
          width="4"
          height="4"
          transform="rotate(-45 59.397 104.652)"
        />
        <rect
          x="67.882"
          y="96.166"
          width="4"
          height="4"
          transform="rotate(-45 67.882 96.166)"
        />
        <rect
          x="76.368"
          y="87.681"
          width="4"
          height="4"
          transform="rotate(-45 76.368 87.681)"
        />
        <rect
          x="104.652"
          y="59.397"
          width="4"
          height="4"
          transform="rotate(45 104.652 59.397)"
        />
        <rect
          x="96.166"
          y="67.882"
          width="4"
          height="4"
          transform="rotate(45 96.166 67.882)"
        />
        <rect
          x="87.681"
          y="76.368"
          width="4"
          height="4"
          transform="rotate(45 87.681 76.368)"
        />
      </g>
      <g>
        <rect
          x="16.971"
          y="79.196"
          width="4"
          height="4"
          transform="rotate(-45 16.97 79.196)"
        />
        <rect
          x="25.456"
          y="87.681"
          width="4"
          height="4"
          transform="rotate(-45 25.456 87.681)"
        />
        <rect
          x="33.941"
          y="96.166"
          width="4"
          height="4"
          transform="rotate(-45 33.941 96.166)"
        />
        <rect
          x="42.426"
          y="104.652"
          width="4"
          height="4"
          transform="rotate(-45 42.426 104.652)"
        />
        <rect
          x="50.912"
          y="113.137"
          width="4"
          height="4"
          transform="rotate(-45 50.912 113.137)"
        />
        <rect
          x="59.397"
          y="121.622"
          width="4"
          height="4"
          transform="rotate(-45 59.397 121.622)"
        />
        <rect
          x="67.882"
          y="113.137"
          width="4"
          height="4"
          transform="rotate(-45 67.882 113.137)"
        />
        <rect
          x="76.368"
          y="104.652"
          width="4"
          height="4"
          transform="rotate(-45 76.368 104.652)"
        />
        <rect
          x="84.853"
          y="96.166"
          width="4"
          height="4"
          transform="rotate(-45 84.853 96.166)"
        />
        <rect
          x="104.652"
          y="76.368"
          width="4"
          height="4"
          transform="rotate(45 104.652 76.368)"
        />
        <rect
          x="96.166"
          y="84.853"
          width="4"
          height="4"
          transform="rotate(45 96.166 84.853)"
        />
      </g>
    </g>
  </SvgContainer>
);

const bounce = keyframes`
  0% {
  transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const SvgContainer = styled.svg`
  display: block;
  margin: 0 auto 40px;
  transition: transform 300ms ease;
  :hover {
    transition: transform 300ms ease;
    g:nth-child(1) {
      animation: ${bounce} 600ms ease-in-out;
    }
    g:nth-child(2) {
      animation: ${bounce} 450ms ease-in-out;
    }
    g:nth-child(3) {
      animation: ${bounce} 300ms ease-in-out;
    }
  }
  @media (max-width: 680px) {
    margin-top: -50px;
  }
`;

export default DownArrow;
