import React from 'react';

// styled
import Section from '../styled/Section';
import Wrapper from '../styled/Wrapper';
import DateText from '../styled/DateText';
import Superscript from '../styled/Superscript';
import Description from '../styled/Description';
import Button from '../styled/Button';

// assets
import toronto from '../../assets/toronto.jpg';

const Hero = () => (
  <Section backgroundColor="#444" backgroundImage={toronto}>
    <Wrapper style={{ textAlign: 'center' }}>
      <DateText>September 12, 2019, Toronto</DateText>
      <Superscript center>The JS Testing Conference</Superscript>
      <Description style={{ marginBottom: 60 }}>
        Assert(js) is a conference for JavaScript developers focused on testing. From TDD techniques and process to JS testing tools and frameworks, both UI and Node.js.
      </Description>
      <Button href="https://www.youtube.com/playlist?list=PLZ66c9_z3umMtAboEKsHXQWB0YMJje7Tl">
        Watch the talks
      </Button>
    </Wrapper>
  </Section>
);

export default Hero;
